import crypto from "crypto";
import { PUBLIC_KEY } from "config";

export function encryptWithPublicKey(plainText) {
  if (plainText == "" || plainText == null || typeof plainText == "undefined") {
    return null;
  }
  const buffer = Buffer.from(plainText);
  return crypto
    .publicEncrypt(
      { key: PUBLIC_KEY, padding: crypto.constants.RSA_PKCS1_PADDING },
      buffer
    )
    .toString("base64");
}

export function isLoggedIn() {
  if (sessionStorage.length === 0) {
    return false;
  }
  let accessToken = sessionStorage.getItem("accessToken");
  if (
    accessToken === "" ||
    accessToken === null ||
    accessToken === "undefined"
  ) {
    return false;
  } else {
    return true;
  }
}

export async function removeSession() {
  await window.sessionStorage.removeItem("accessToken");
  await window.sessionStorage.removeItem("refreshToken");
  await window.sessionStorage.removeItem("user");
  await window.sessionStorage.removeItem("accessLevel");
  await window.sessionStorage.removeItem("stateOfficerProfile");
  await window.sessionStorage.removeItem("districtOfficerProfile");
}

export const get = (p) => (o) =>
  p.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), o);

// export async function loginRequired() {
//   if (sessionStorage.length !== 0) {
//     let accessToken = await sessionStorage.getItem("accessToken");
//     if (
//       accessToken === "" ||
//       accessToken === null ||
//       accessToken === "undefined"
//     ) {
//       window.location.replace("/auth/login");
//     }
//   } else {
//     window.location.replace("/auth/login");
//   }
// }

export function popNotificationComponent(props, message, variant) {
  props.enqueueSnackbar(message, { variant: variant });
}

export function popNotificationFunctional(enqueueSnackbar, message, variant) {
  enqueueSnackbar(message, { variant: variant });
}

export function generateNotificationComponent(res, props, success_message) {
  let status = get(["status"])(res);
  let error = "";
  if (status === null) {
    error = get(["data", "detail"])(res);
    if (error === null) {
      error = "Please check your credentials!";
    }
    props.enqueueSnackbar(error, { variant: "error" });
  } else if (status !== 200 && status !== 201) {
    error = get(["data", "detail"])(res);
    if (error === null) {
      error = "Sorry! Please try again later";
    }
    props.enqueueSnackbar(error, { variant: "error" });
  } else {
    props.enqueueSnackbar(success_message, { variant: "success" });
  }
}

export function generateNotificationFunctional(
  res,
  enqueueSnackbar,
  success_message
) {
  let status = get(["status"])(res);
  let error = "";
  if (status === null) {
    error = get(["data", "detail"])(res);
    if (error === null) {
      error = "Please check your credentials!";
    }
    enqueueSnackbar(error, { variant: "error" });
  } else if (status !== 200 && status !== 201) {
    let data = get(["data"])(res);
    let error_fields = get(["data", "errors"])(res);
    let detail = get(["detail"])(data);
    if (detail !== null) {
      error = detail;
    } else if (error_fields) {
      let error_fields_keys = Object.keys(error_fields);
      for (let i in error_fields_keys) {
        for (let error in error_fields[error_fields_keys[i]]) {
          enqueueSnackbar(
            error_fields_keys[i] +
              ": " +
              error_fields[error_fields_keys[i]][error],
            {
              variant: "error",
            }
          );
        }
      }
    } else {
      enqueueSnackbar("Sorry! An error occurred please try again later.", {
        variant: "error",
      });
    }
  } else {
    enqueueSnackbar(success_message, { variant: "success" });
  }
}

export function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export function getFullFormAbbrevation(abb, accessLevel) {
  let fullForm = abb;
  if (accessLevel === "STATEOFFICER") {
    switch (abb) {
      case "SPM":
        fullForm = "State Program Manager";
        break;
      case "NHM":
        fullForm = "NHM MD";
        break;
      case "SME":
        fullForm = "SME";
        break;
      case "SMS":
        fullForm = "SMS";
        break;
      case "SPH":
        fullForm = "SPH";
        break;
      default:
        fullForm = abb;
        break;
    }
  } else {
    switch (abb) {
      case "DDHS":
        fullForm = "District Director HS";
        break;
      case "JDHS":
        fullForm = "District Joint director HS";
        break;
      case "DPM":
        fullForm = "District Program Manager";
        break;
      case "DO":
        fullForm = "District Ophthalmologist";
        break;
      default:
        fullForm = abb;
        break;
    }
  }
  return fullForm;
}
