import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { TextField, Backdrop, CircularProgress } from "@material-ui/core";
import { useSnackbar } from "notistack";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import MaterialTable from "material-table";

import { addFieldWorker, updateFieldWorker, getDistricts } from "utils/apis";
import { generateNotificationFunctional, get } from "utils/utilities";

import { API_SERVER } from "config";
import { encryptWithPublicKey } from "utils/utilities";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const backdropStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const useStyles = makeStyles(styles);

export default function TableList() {
  const backdropClasses = backdropStyles();
  const accessLevel = sessionStorage.getItem("accessLevel");
  const classes = useStyles();
  const tableRef = React.createRef();
  const { enqueueSnackbar } = useSnackbar();
  const ref = React.createRef();

  const [initialDataLoaded, setInitialDataLoaded] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [districtsLookup, setDistrictsLookup] = React.useState({});

  React.useEffect(() => {
    async function loadInitialData() {
      if (initialDataLoaded) {
        return;
      }
      setLoading(true);
      let res = await getDistricts();
      let districts = get(["data", "results"])(res);
      let districtsLookup = {};
      for (let i in districts) {
        districtsLookup[districts[i]["id"]] = districts[i]["name"];
      }
      setDistrictsLookup(districtsLookup);
      setInitialDataLoaded(true);
      setLoading(false);
    }
    loadInitialData();
    return () => {
      setInitialDataLoaded(true);
      setLoading(false);
    };
  });

  return (
    <React.Fragment>
      <Backdrop className={backdropClasses.backdrop} open={loading} ref={ref}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Field Workers</h4>
              <p className={classes.cardCategoryWhite}>
                List of all the the field workers
              </p>
            </CardHeader>
            <CardBody>
              <MaterialTable
                title="Field Worker Details"
                tableRef={tableRef}
                columns={[
                  { title: "ID", field: "custom_id", editable: "never" },
                  {
                    title: "First Name",
                    field: "user.first_name",
                  },
                  {
                    title: "Last Name",
                    field: "user.last_name",
                  },
                  {
                    title: "Email",
                    filtering: false,
                    field: "user.email",
                  },
                  {
                    title: "Password",
                    field: "user.password",
                    type: "string",
                    render: (rowData) => {
                      return <p>*****</p>;
                    },
                    editComponent: (props) => {
                      return (
                        <TextField
                          type="password"
                          value={props.value}
                          onChange={(e) => props.onChange(e.target.value)}
                        />
                      );
                    },
                  },
                  {
                    title: "Active",
                    field: "active",
                    type: "boolean",
                  },
                  {
                    title: "Expires At",
                    field: "expires_at",
                    type: "date",
                  },
                  {
                    title: "District",
                    field: "district.id",
                    filtering: false,
                    lookup: districtsLookup,
                  },
                  {
                    title: "Contact No",
                    filtering: false,
                    field: "user.contact_no",
                    render: (rowData) => {
                      return rowData.user.contact_no;
                    },
                  },
                ]}
                data={(query) =>
                  new Promise((resolve, reject) => {
                    let url = API_SERVER + "/dashboard/field_workers/?";
                    url += "per_page=" + query.pageSize;
                    url += "&page=" + (query.page + 1);
                    if (query.search !== null) {
                      url += "&search=" + query.search;
                    }
                    if (accessLevel === "DISTRICTOFFICER") {
                      let districts = [];
                      let districtOfficerProfile = JSON.parse(
                        sessionStorage.getItem("districtOfficerProfile")
                      );
                      districts = [districtOfficerProfile["district"]["id"]];
                      if (districts.length > 0) {
                        let districtsParams = "[";
                        for (let i in districts) {
                          if (Number.isInteger(districts[i])) {
                            districtsParams += districts[i];
                            if (
                              parseInt(i) !== parseInt(districts.length - 1)
                            ) {
                              districtsParams += ",";
                            }
                          }
                        }
                        districtsParams += "]";
                        url += "&districts=" + districtsParams;
                      }
                    }
                    axios
                      .get(url, {
                        headers: {
                          Authorization:
                            "Bearer " + sessionStorage.getItem("accessToken"),
                        },
                      })
                      // .get(url, {})
                      // .then(response => response.json())
                      .then((result) => {
                        resolve({
                          data: result.data.results,
                          page: query.page,
                          totalCount: result.data.count,
                        });
                      });
                  })
                }
                editable={{
                  onRowAdd: (newData) =>
                    new Promise(async (resolve, reject) => {
                      let data = {};
                      data["first_name"] = newData["user"]["first_name"];
                      data["last_name"] = newData["user"]["last_name"];
                      data["contact_no"] = newData["user"]["contact_no"];
                      data["email"] = newData["user"]["email"];
                      data["active"] = newData["active"];
                      data["district"] = newData["district"]["id"];
                      data["password"] = encryptWithPublicKey(
                        newData["user"]["password"]
                      );
                      let newExpiryDate = null;
                      if (typeof newData["expires_at"] === "string") {
                        newExpiryDate = newData["expires_at"].split("-");
                        data["expires_at"] =
                          newExpiryDate[2] +
                          "-" +
                          newExpiryDate[1] +
                          "-" +
                          newExpiryDate[0];
                      } else {
                        newExpiryDate =
                          newData["expires_at"].getDate() +
                          "-" +
                          parseInt(
                            parseInt(newData["expires_at"].getMonth()) + 1
                          ) +
                          "-" +
                          newData["expires_at"].getFullYear();
                        data["expires_at"] = newExpiryDate;
                      }
                      await addFieldWorker(data)
                        .then((res) => {
                          generateNotificationFunctional(
                            res,
                            enqueueSnackbar,
                            "Field Worker's detail has been added successfully!"
                          );
                          resolve();
                        })
                        .catch((error) => {
                          reject(error);
                        });
                    }),
                  onRowUpdate: (newData, oldData) =>
                    new Promise(async (resolve, reject) => {
                      let data = {};
                      data["first_name"] = newData["user"]["first_name"];
                      data["last_name"] = newData["user"]["last_name"];
                      data["contact_no"] = newData["user"]["contact_no"];
                      data["email"] = newData["user"]["email"];
                      data["active"] = newData["active"];
                      data["district"] = newData["district"]["id"];
                      data["password"] = encryptWithPublicKey(
                        newData["user"]["password"]
                      );
                      let newExpiryDate = null;
                      if (typeof newData["expires_at"] === "string") {
                        newExpiryDate = newData["expires_at"].split("-");
                        data["expires_at"] =
                          newExpiryDate[2] +
                          "-" +
                          newExpiryDate[1] +
                          "-" +
                          newExpiryDate[0];
                      } else {
                        newExpiryDate =
                          newData["expires_at"].getDate() +
                          "-" +
                          parseInt(
                            parseInt(newData["expires_at"].getMonth()) + 1
                          ) +
                          "-" +
                          newData["expires_at"].getFullYear();
                        data["expires_at"] = newExpiryDate;
                      }
                      data["id"] = newData["id"];
                      await updateFieldWorker(data)
                        .then((res) => {
                          generateNotificationFunctional(
                            res,
                            enqueueSnackbar,
                            "Field Worker's detail has been updated successfully!"
                          );
                          resolve();
                        })
                        .catch((error) => {
                          reject(error);
                        });
                    }),
                }}
                actions={[
                  {
                    icon: "refresh",
                    tooltip: "Refresh Data",
                    isFreeAction: true,
                    onClick: () =>
                      tableRef.current && tableRef.current.onQueryChange(),
                  },
                ]}
                options={{
                  search: true,
                  exportButton: true,
                  grouping: true,
                  pageSize: 100,
                  pageSizeOptions: [
                    10,
                    20,
                    30,
                    50,
                    75,
                    100,
                    500,
                    1000,
                    2000,
                    5000,
                  ],
                  headerStyle: {
                    zIndex: 8,
                  },
                  maxBodyHeight: 500,
                  // filtering: true
                }}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </React.Fragment>
  );
}
