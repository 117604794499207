import React from "react";
import { useHistory } from "react-router-dom";
import { logout } from "utils/apis";
import { removeSession } from "utils/utilities";

export default function Logout() {
  const history = useHistory();

  // componentDidMount() {
  //   removeSession();
  //   history.push("/auth/login");
  //   // window.location.replace("/auth/login");
  // }

  React.useEffect(() => {
    logout();
    removeSession();
    // history.push("/auth/login");
  });

  return <React.Fragment>Logging out</React.Fragment>;
}
