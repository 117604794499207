import React from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
// @material-ui/icons
import Store from "@material-ui/icons/Store";
// import DetailsIcon from "@material-ui/icons/Details";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import AvTimerIcon from "@material-ui/icons/AvTimer";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import AsyncSelect from "react-select/async";
import Select from "react-select";
// import makeAnimated from "react-select/animated";
import IconButton from "@material-ui/core/IconButton";
import { useSnackbar } from "notistack";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
// import CardFooter from "components/Card/CardFooter.js";
import Grid from "@material-ui/core/Grid";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import UpdateIcon from "@material-ui/icons/Update";
import axios from "axios";

import {
  screeningDistributionChart,
  positiveCasesBarChart,
} from "variables/charts.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { getStats } from "utils/apis";
import { get, generateNotificationFunctional } from "utils/utilities";
import { API_SERVER } from "config";

const useStyles = makeStyles(styles);

const backdropStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
// const animatedComponents = makeAnimated();
// const options = [
//   { value: "chocolate", label: "Chocolate" },
//   { value: "strawberry", label: "Strawberry" },
//   { value: "vanilla", label: "Vanilla" },
// ];

export default function Dashboard() {
  const classes = useStyles();
  const backdropClasses = backdropStyles();

  const accessLevel = sessionStorage.getItem("accessLevel");
  const today = new Date();
  const [selectedFromDate, setSelectedFromDate] = React.useState(
    new Date(today.getFullYear(), today.getMonth(), 1)
  );
  const { enqueueSnackbar } = useSnackbar();
  const [selectedToDate, setSelectedToDate] = React.useState(new Date());
  const [totalScreenings, setTotalScreenings] = React.useState(0);
  const [totalSurgeries, setTotalSurgeries] = React.useState(0);
  const [totalDelays, setTotalDelays] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [districts, setDistricts] = React.useState({});
  const [selectedDistricts, setSelectedDistricts] = React.useState([]);
  const [
    selectedDistrictsFromSearch,
    setSelectedDistrictsFromSearch,
  ] = React.useState([]);
  const [
    positiveCasesBarChartData,
    setPositiveCasesBarChartData,
  ] = React.useState({});
  const [
    screeningDistributionChartData,
    setScreeningDistributionChartData,
  ] = React.useState({});
  const ref = React.createRef();

  async function loadInitialData() {
    setLoading(true);
    let data = [];
    data["fromDate"] =
      selectedFromDate.getDate() +
      "-" +
      parseInt(parseInt(selectedFromDate.getMonth()) + 1) +
      "-" +
      selectedFromDate.getFullYear();
    data["toDate"] =
      selectedToDate.getDate() +
      "-" +
      parseInt(parseInt(selectedToDate.getMonth()) + 1) +
      "-" +
      selectedToDate.getFullYear();
    data["districts"] = [];
    if (accessLevel === "STATEOFFICER") {
      data["districts"] = selectedDistricts;
    } else {
      let districtOfficerProfile = JSON.parse(
        sessionStorage.getItem("districtOfficerProfile")
      );
      let selectedDistrict = {
        label: get(["district", "name"])(districtOfficerProfile),
        value: get(["district", "id"])(districtOfficerProfile),
      };
      setSelectedDistrictsFromSearch([selectedDistrict]);
      data["districts"] = [get(["district", "id"])(districtOfficerProfile)];
    }
    let res = await getStats(data);
    generateNotificationFunctional(
      res,
      enqueueSnackbar,
      "Stats have been updated successfully!"
    );
    let total_screenings = get(["data", "total_screenings"])(res);
    setTotalScreenings(total_screenings === null ? 0 : total_screenings);
    let total_delays = get(["data", "total_delays"])(res);
    setTotalDelays(total_delays === null ? 0 : total_delays);
    let total_surgeries = get(["data", "total_operations"])(res);
    setTotalSurgeries(total_surgeries === null ? 0 : total_surgeries);
    let rawBarChartData = get(["data", "bar_chart_data"])(res);
    if (rawBarChartData !== null) {
      let barChartLabels = [];
      let barChartSeries = [];
      for (let i in rawBarChartData) {
        barChartLabels.push(rawBarChartData[i]["screening_date"]);
        barChartSeries.push(rawBarChartData[i]["total_cases"]);
      }
      let barChartData = {
        labels: barChartLabels,
        series: [barChartSeries],
      };
      setPositiveCasesBarChartData(barChartData);
    } else {
      setPositiveCasesBarChartData({});
    }
    let rawPieChartData = get(["data", "pie_chart_data"])(res);
    if (rawPieChartData !== null) {
      let pieChartLabels = [];
      let pieChartSeries = [];
      for (let i in rawPieChartData) {
        pieChartLabels.push(rawPieChartData[i]["status"]);
        pieChartSeries.push(rawPieChartData[i]["total_cases"]);
      }
      let pieChartData = {
        labels: pieChartLabels,
        series: pieChartSeries,
      };
      setScreeningDistributionChartData(pieChartData);
    } else {
      setScreeningDistributionChartData({});
    }
    setLoading(false);
  }

  const loadDistricts = (inputValue) =>
    new Promise((resolve, reject) => {
      let url = API_SERVER + "/dashboard/districts/?";
      url += "search=" + inputValue;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
          },
        })
        .then((result) => {
          setDistricts(result.data.results);
          let filteredDistricts = [];
          districts.forEach((district) => {
            filteredDistricts.push({
              value: district.id,
              label: String(district.name) + "[" + String(district.code) + "]",
            });
          });
          resolve(filteredDistricts);
        })
        .catch((error) => {
          reject(error);
        });
    });

  const handleDistrictSelectOnchange = async (districts, key) => {
    let selectedDistrictsIds = [];
    switch (key.action) {
      case "select-option":
        selectedDistrictsIds = districts.map((district) => {
          return district.value;
        });
        await setSelectedDistricts(selectedDistrictsIds);
        break;
      case "remove-value":
        if (districts != null && districts.length > 0) {
          selectedDistrictsIds = districts.map((district) => {
            return district.value;
          });
          await setSelectedDistricts(selectedDistrictsIds);
        } else {
          await setSelectedDistricts([]);
        }
        break;
      case "clear":
        await setSelectedDistricts([]);
        break;
      default:
        console.log("default case");
    }
  };

  React.useEffect(() => {
    async function loadInitialData() {
      setLoading(true);
      let data = [];
      data["fromDate"] =
        selectedFromDate.getDate() +
        "-" +
        parseInt(parseInt(selectedFromDate.getMonth()) + 1) +
        "-" +
        selectedFromDate.getFullYear();
      data["toDate"] =
        selectedToDate.getDate() +
        "-" +
        parseInt(parseInt(selectedToDate.getMonth()) + 1) +
        "-" +
        selectedToDate.getFullYear();
      data["districts"] = [];
      if (accessLevel === "STATEOFFICER") {
        data["districts"] = selectedDistricts;
      } else {
        let districtOfficerProfile = JSON.parse(
          sessionStorage.getItem("districtOfficerProfile")
        );
        let selectedDistrict = {
          label: districtOfficerProfile["district"]["name"],
          value: districtOfficerProfile["district"]["id"],
        };
        setSelectedDistrictsFromSearch([selectedDistrict]);
        data["districts"] = [districtOfficerProfile["district"]["id"]];
      }
      let res = await getStats(data);
      generateNotificationFunctional(
        res,
        enqueueSnackbar,
        "Stats have been updated successfully!"
      );
      let total_screenings = get(["data", "total_screenings"])(res);
      setTotalScreenings(total_screenings === null ? 0 : total_screenings);
      let total_delays = get(["data", "total_delays"])(res);
      setTotalDelays(total_delays === null ? 0 : total_delays);
      let total_surgeries = get(["data", "total_operations"])(res);
      setTotalSurgeries(total_surgeries === null ? 0 : total_surgeries);
      let rawBarChartData = get(["data", "bar_chart_data"])(res);
      if (rawBarChartData !== null) {
        let barChartLabels = [];
        let barChartSeries = [];
        for (let i in rawBarChartData) {
          barChartLabels.push(rawBarChartData[i]["screening_date"]);
          barChartSeries.push(rawBarChartData[i]["total_cases"]);
        }
        let barChartData = {
          labels: barChartLabels,
          series: [barChartSeries],
        };
        setPositiveCasesBarChartData(barChartData);
      } else {
        setPositiveCasesBarChartData({});
      }
      let rawPieChartData = get(["data", "pie_chart_data"])(res);
      if (rawPieChartData !== null) {
        let pieChartLabels = [];
        let pieChartSeries = [];
        for (let i in rawPieChartData) {
          pieChartLabels.push(rawPieChartData[i]["status"]);
          pieChartSeries.push(rawPieChartData[i]["total_cases"]);
        }
        let pieChartData = {
          labels: pieChartLabels,
          series: pieChartSeries,
        };
        setScreeningDistributionChartData(pieChartData);
      } else {
        setScreeningDistributionChartData({});
      }
      setLoading(false);
    }
    loadInitialData();
    return () => {
      setLoading(false);
    };
  }, [
    selectedToDate,
    selectedFromDate,
    accessLevel,
    enqueueSnackbar,
    selectedDistricts,
  ]);

  return (
    <React.Fragment>
      <Backdrop className={backdropClasses.backdrop} open={loading} ref={ref}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <GridItem xs={12} sm={6} md={3}>
                    <KeyboardDatePicker
                      margin="normal"
                      id="date-picker-from-date-dialog"
                      label="Select From Date"
                      format="dd/MM/yyyy"
                      value={selectedFromDate}
                      onChange={(date) => {
                        setSelectedFromDate(date);
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={3}>
                    <KeyboardDatePicker
                      margin="normal"
                      id="date-picker-to-date-dialog"
                      label="Select To Date"
                      format="dd/MM/yyyy"
                      value={selectedToDate}
                      onChange={(date) => {
                        setSelectedToDate(date);
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={5}
                    style={{ marginTop: 20, marginBottom: 8 }}
                  >
                    {accessLevel === "STATEOFFICER" ? (
                      <AsyncSelect
                        cacheOptions
                        defaultOptions
                        loadOptions={loadDistricts}
                        onChange={handleDistrictSelectOnchange}
                        isMulti
                        styles={{
                          // Fixes the overlapping problem of the component
                          menu: (provided) => ({ ...provided, zIndex: 9999 }),
                        }}
                      />
                    ) : (
                      <Select
                        isMulti
                        value={selectedDistrictsFromSearch}
                        isClearable={false}
                        name="districts"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        isDisabled={true}
                      />
                    )}
                  </GridItem>
                  <GridItem style={{ marginTop: 20, marginBottom: 8 }}>
                    <IconButton
                      variant="contained"
                      color="inherit"
                      aria-label="Refresh Stats"
                      onClick={() => loadInitialData()}
                    >
                      <UpdateIcon />
                    </IconButton>
                  </GridItem>
                </Grid>
              </MuiPickersUtilsProvider>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={4} md={4}>
          <Card>
            <CardHeader color="primary" stats icon>
              <CardIcon color="primary">
                <Store />
              </CardIcon>
              <p className={classes.cardCategory}>Total Screenings</p>
              <h3 className={classes.cardTitle}>{totalScreenings}</h3>
            </CardHeader>
            {/* <CardFooter stats>
              <div className={classes.stats}>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  <DetailsIcon />
                  View All
                </a>
              </div>
            </CardFooter> */}
          </Card>
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <Card>
            <CardHeader color="primary" stats icon>
              <CardIcon color="primary">
                <LocalHospitalIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Total Surgeries</p>
              <h3 className={classes.cardTitle}>{totalSurgeries}</h3>
            </CardHeader>
            {/* <CardFooter stats>
              <div className={classes.stats}>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  <DetailsIcon />
                  View All
                </a>
              </div>
            </CardFooter> */}
          </Card>
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <AvTimerIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Total Delays</p>
              <h3 className={classes.cardTitle}>{totalDelays}</h3>
            </CardHeader>
            {/* <CardFooter stats>
              <div className={classes.stats}>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  <DetailsIcon />
                  View All
                </a>
              </div>
            </CardFooter> */}
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card chart>
            <CardHeader color="primary">
              <ChartistGraph
                className="ct-chart"
                data={positiveCasesBarChartData}
                type="Bar"
                options={positiveCasesBarChart.options}
                responsiveOptions={positiveCasesBarChart.responsiveOptions}
                listener={positiveCasesBarChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Positive Cases Reported</h4>
              <p className={classes.cardCategory}>
                From {selectedFromDate.toDateString()} -{" "}
                {selectedToDate.toDateString()}
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card chart>
            <CardHeader color="primary">
              <ChartistGraph
                className="ct-chart"
                data={screeningDistributionChartData}
                type={"Pie"}
                options={screeningDistributionChart.options}
                responsiveOptions={screeningDistributionChart.responsiveOptions}
                listener={screeningDistributionChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Screening Distribution</h4>
              <p className={classes.cardCategory}>
                From {selectedFromDate.toDateString()} -{" "}
                {selectedToDate.toDateString()}
              </p>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </React.Fragment>
  );
}
