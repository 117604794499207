import axios from "axios";
import { API_SERVER } from "config";
import { removeSession } from "./utilities";
// import { get as util_get } from "utils/utilities";

const cleanAxios = axios.create();

axios.interceptors.response.use(
  (response) => {
    // do something with the response data
    console.log("Response was received");
    return response;
  },
  (error) => {
    // handle the response error
    console.log("Request Failed");
    const {
      config,
      // response: { status }
    } = error;
    const originalRequest = config;
    console.log(error.response.data);

    if (
      sessionStorage.getItem("accessToken") == null ||
      sessionStorage.getItem("refreshToken") == null
    ) {
      removeSession();
      window.location.replace("/");
      return Promise.reject(error);
    }

    if (
      error.response &&
      error.response.status === 401 &&
      error.response.data.detail === "Token is blacklisted"
    ) {
      console.log("Logging out");
      removeSession();
      window.location.replace("/");
      return Promise.reject(error);
    }

    if (
      error.config &&
      error.response &&
      error.response.status === 401 &&
      error.response.data.detail ===
        "Given token not valid for any token type" &&
      error.response.data.code === "token_not_valid"
    ) {
      return getNewTokens()
        .then(function () {
          const retryOrigReq = new Promise((resolve, reject) => {
            // replace the expired token and retry
            originalRequest.headers["Authorization"] =
              "Bearer " + sessionStorage.getItem("accessToken");
            resolve(axios(originalRequest));
          });
          return retryOrigReq;
        })
        .catch(function () {
          console.log("Logging out");
          removeSession();
          window.location.replace("/");
          return Promise.reject(error);
        });
    }
    return Promise.reject(error);
  }
);

async function get(endpoint, headers, params) {
  let url = API_SERVER + endpoint;
  return await axios
    .get(url, { headers: headers, params: params })
    .then(function (res) {
      return res;
    })
    .catch(function (err) {
      return err.response;
    });
}

// async function _delete(endpoint, headers, params) {
//   let url = API_SERVER + endpoint;
//   return await axios
//     .delete(url, { headers: headers, params: params })
//     .then(function (res) {
//       return res;
//     })
//     .catch(function (err) {
//       return err.response;
//     });
// }

async function post(endpoint, data, headers, params) {
  let url = API_SERVER + endpoint;
  return await axios
    .post(url, data, { headers: headers, params: params })
    .then(function (res) {
      return res;
    })
    .catch(function (error) {
      return error.response;
      // return Promise.reject(error);
    });
}

async function put(endpoint, data, headers, params) {
  let url = API_SERVER + endpoint;
  return await axios
    .put(url, data, { headers: headers, params: params })
    .then(function (res) {
      return res;
    })
    .catch(function (err) {
      return err.response;
    });
}

export async function getNewTokens() {
  let data = {
    refresh: await sessionStorage.getItem("refreshToken"),
  };
  await post("/auth/refresh/", data, {}, {})
    .then(async function (res) {
      if (res.status === 200) {
        await sessionStorage.setItem("refreshToken", res.data.refresh);
        await sessionStorage.setItem("accessToken", res.data.access);
      }
    })
    .catch(function (err) {
      window.location.replace("/");
    });
}

export async function login(data) {
  let url = API_SERVER + "/auth/login/";
  return await cleanAxios
    .post(url, data, {})
    .then(function (res) {
      return res;
    })
    .catch(function (error) {
      return error.response;
      // Promise.reject(error);
    });
}

export async function logout() {
  let data = {
    refresh: sessionStorage.getItem("refreshToken"),
  };
  await post(
    "/auth/logout/",
    data,
    {
      Authorization: "Bearer " + (await sessionStorage.getItem("accessToken")),
    },
    {}
  )
    .then(async function (res) {
      window.location.replace("/");
    })
    .catch(function (err) {
      window.location.replace("/");
    });
}

// export async function getProfile() {
//   let header = {
//     Authorization: "Bearer " + sessionStorage("accessToken"),
//   };
// }

export async function sendOTP(data) {
  return await post("auth/send_otp/", data, {}, {});
}

export async function verifyOTP(data) {
  return await post("auth/verify/otp/", data, {}, {});
}

export async function updatePassword(data) {
  return await post("auth/update/password/", data, {}, {});
}

export async function getStats(data) {
  let headers = {
    Authorization: "Bearer " + (await sessionStorage.getItem("accessToken")),
  };
  let urlParams =
    "?from_date=" + data["fromDate"] + "&to_date=" + data["toDate"];
  if (data["districts"].length > 0) {
    let districtsParams = "[";
    for (let i in data["districts"]) {
      if (Number.isInteger(data["districts"][i])) {
        districtsParams += data["districts"][i];
        if (parseInt(i) !== parseInt(data["districts"].length - 1)) {
          districtsParams += ",";
        }
      }
    }
    districtsParams += "]";
    urlParams += "&districts=" + districtsParams;
  }
  return await get("/dashboard/stats/" + urlParams, headers);
}

export async function addDistrict(data) {
  let headers = {
    Authorization: "Bearer " + (await sessionStorage.getItem("accessToken")),
  };
  return await post("/dashboard/districts/", data, headers, {});
}

export async function updateDistrict(data) {
  let headers = {
    Authorization: "Bearer " + (await sessionStorage.getItem("accessToken")),
  };
  return await put(
    "/dashboard/districts/" + data["id"] + "/",
    data,
    headers,
    {}
  );
}

export async function transferPatientToDistrict(data) {
  let headers = {
    Authorization: "Bearer " + (await sessionStorage.getItem("accessToken")),
  };
  return await put(
    "/dashboard/screenings/" + data["id"] + "/",
    data,
    headers,
    {}
  );
}

export async function getProfile(data) {
  let headers = {
    Authorization: "Bearer " + (await sessionStorage.getItem("accessToken")),
  };
  return await get("/auth/profile/", headers);
}

export async function updateProfile(data) {
  let headers = {
    Authorization: "Bearer " + (await sessionStorage.getItem("accessToken")),
  };
  return await put("/auth/profile/", data, headers, {});
}

export async function getDistricts(data) {
  let headers = {
    Authorization: "Bearer " + (await sessionStorage.getItem("accessToken")),
  };
  return await get("/dashboard/districts/", headers);
}

export async function updateDistrictOfficer(data) {
  let headers = {
    Authorization: "Bearer " + (await sessionStorage.getItem("accessToken")),
  };
  return await put("/dashboard/district_officers/", data, headers, {});
}

export async function addDistrictOfficer(data) {
  let headers = {
    Authorization: "Bearer " + (await sessionStorage.getItem("accessToken")),
  };
  return await post("/dashboard/district_officers/", data, headers, {});
}

export async function updateStateOfficer(data) {
  let headers = {
    Authorization: "Bearer " + (await sessionStorage.getItem("accessToken")),
  };
  return await put("/dashboard/state_officers/", data, headers, {});
}

export async function addStateOfficer(data) {
  let headers = {
    Authorization: "Bearer " + (await sessionStorage.getItem("accessToken")),
  };
  return await post("/dashboard/state_officers/", data, headers, {});
}

export async function addFieldWorker(data) {
  let headers = {
    Authorization: "Bearer " + (await sessionStorage.getItem("accessToken")),
  };
  return await post("/dashboard/field_workers/", data, headers, {});
}

export async function updateFieldWorker(data) {
  let headers = {
    Authorization: "Bearer " + (await sessionStorage.getItem("accessToken")),
  };
  return await put("/dashboard/field_workers/", data, headers, {});
}

export async function updateScreeningPrediction(data) {
  let headers = {
    Authorization: "Bearer " + (await sessionStorage.getItem("accessToken")),
  };
  return await put(
    "/dashboard/screenings/" + data.id + "/validation/",
    data,
    headers,
    {}
  );
}

export async function updateScreeningStatusToOperated(data) {
  let headers = {
    Authorization: "Bearer " + (await sessionStorage.getItem("accessToken")),
  };
  return await put(
    "/dashboard/screenings/" + data.id + "/status/",
    data,
    headers,
    {}
  );
}
