// ##############################
// // // javascript library for creating charts
// #############################
// var Chartist = require("chartist");

// ##############################
// // // variables used to create animation on charts
// #############################
// var delays = 80,
//   durations = 500;
var delays2 = 80,
  durations2 = 500;

// ##############################
// // // Positive Cases Bar Chart
// #############################

const positiveCasesBarChart = {
  // data: {
  //   labels: [
  //     "Jan",
  //     "Feb",
  //     "Mar",
  //     "Apr",
  //     "Mai",
  //     "Jun",
  //     "Jul",
  //     "Aug",
  //     "Sep",
  //     "Oct",
  //     "Nov",
  //     "Dec",
  //   ],
  //   series: [[542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895]],
  // },
  options: {
    axisX: {
      showGrid: false,
    },
    low: 0,
    high: 100,
    chartPadding: {
      top: 0,
      right: 5,
      bottom: 0,
      left: 0,
    },
  },
  responsiveOptions: [
    [
      "screen and (max-width: 640px)",
      {
        seriesBarDistance: 5,
        axisX: {
          labelInterpolationFnc: function (value) {
            return value[0];
          },
        },
      },
    ],
  ],
  animation: {
    draw: function (data) {
      if (data.type === "bar") {
        data.element.animate({
          opacity: {
            begin: (data.index + 1) * delays2,
            dur: durations2,
            from: 0,
            to: 1,
            easing: "ease",
          },
        });
      }
    },
  },
};

// ##############################
// // // Screening Distribution
// #############################

const screeningDistributionChart = {
  data: {
    labels: ["Operated", "Referred", "Delayed"],
    series: [542, 443, 320],
  },
  options: {
    donut: true,
    showLabel: true,
    donutWidth: 40,
    donutSolid: true,
    startAngle: 270,
    // chartPadding: {
    //   top: 0,
    //   right: 5,
    //   bottom: 0,
    //   left: 0,
    // },
  },
  responsiveOptions: [
    [
      "screen and (max-width: 640px)",
      {
        seriesBarDistance: 5,
        axisX: {
          labelInterpolationFnc: function (value) {
            return value[0];
          },
        },
      },
    ],
  ],
  animation: {
    draw: function (data) {
      if (data.type === "slice") {
        data.element.animate({
          opacity: {
            begin: (data.index + 1) * delays2,
            dur: durations2,
            from: 0,
            to: 1,
            easing: "ease",
            fill: "freeze",
          },
        });
      }
    },
  },
};

module.exports = {
  positiveCasesBarChart,
  screeningDistributionChart,
};
