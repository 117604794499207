import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
// core components
import { TextField } from "@material-ui/core";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import MaterialTable from "material-table";
import { useSnackbar } from "notistack";
// import { get } from "utils/utilities";
import { generateNotificationFunctional } from "utils/utilities";
import { addStateOfficer, updateStateOfficer } from "utils/apis";

import { API_SERVER } from "config";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export default function TableList() {
  const classes = useStyles();
  const tableRef = React.createRef();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>State Details</h4>
            <p className={classes.cardCategoryWhite}>
              List of all the the State Officers
            </p>
          </CardHeader>
          <CardBody>
            <MaterialTable
              title="State Officers"
              tableRef={tableRef}
              columns={[
                { title: "Officer ID", field: "custom_id", editable: "never" },
                {
                  title: "First Name",
                  field: "user.first_name",
                  initialEditValue: (rowData) => {
                    return rowData.user.first_name;
                  },
                  render: (rowData) => {
                    return rowData.user.first_name;
                  },
                },
                {
                  title: "Last Name",
                  field: "user.last_name",
                  render: function (rowData) {
                    return rowData.user.last_name;
                  },
                },
                {
                  title: "Password",
                  field: "user.password",
                  type: "string",
                  render: (rowData) => {
                    return <p>*****</p>;
                  },
                  editComponent: (props) => {
                    return (
                      <TextField
                        type="password"
                        value={props.value}
                        onChange={(e) => props.onChange(e.target.value)}
                      />
                    );
                  },
                },
                {
                  title: "Email",
                  field: "user.email",
                  render: (rowData) => {
                    return rowData.user.email;
                  },
                },
                {
                  title: "Contact No",
                  field: "user.contact_no",
                  render: (rowData) => {
                    return rowData.user.contact_no;
                  },
                },
                {
                  title: "Type",
                  field: "type",
                  lookup: {
                    SPM: "State Program Manager",
                    NHM: "NHM MD",
                    SME: "SME",
                    SMS: "SMS",
                    SPH: "SPH",
                  },
                },
                {
                  title: "Active",
                  field: "active",
                  type: "boolean",
                },
              ]}
              data={(query) =>
                new Promise((resolve, reject) => {
                  let url = API_SERVER + "/dashboard/state_officers/?";
                  url += "per_page=" + query.pageSize;
                  url += "&page=" + (query.page + 1);
                  if (query.search !== null) {
                    url += "&search=" + query.search;
                  }
                  axios
                    .get(url, {
                      headers: {
                        Authorization:
                          "Bearer " + sessionStorage.getItem("accessToken"),
                      },
                    })
                    // .get(url, {})
                    // .then(response => response.json())
                    .then((result) => {
                      resolve({
                        data: result.data.results,
                        page: query.page,
                        totalCount: result.data.count,
                      });
                    });
                })
              }
              editable={{
                onRowAdd: (newData) =>
                  new Promise(async (resolve, reject) => {
                    let data = {};
                    data["first_name"] = newData["user"]["first_name"];
                    data["last_name"] = newData["user"]["last_name"];
                    data["contact_no"] = newData["user"]["contact_no"];
                    data["email"] = newData["user"]["email"];
                    data["active"] = newData["active"];
                    data["type"] = newData["type"];
                    data["password"] = newData["user"]["password"];
                    await addStateOfficer(data)
                      .then((res) => {
                        generateNotificationFunctional(
                          res,
                          enqueueSnackbar,
                          "State Officers details has been added successfully!"
                        );
                        resolve();
                      })
                      .catch((error) => {
                        reject(error);
                      });
                  }),
                onRowUpdate: (newData, oldData) =>
                  new Promise(async (resolve, reject) => {
                    let data = {};
                    data["first_name"] = newData["user"]["first_name"];
                    data["last_name"] = newData["user"]["last_name"];
                    data["contact_no"] = newData["user"]["contact_no"];
                    data["email"] = newData["user"]["email"];
                    data["active"] = newData["active"];
                    data["type"] = newData["type"];
                    data["id"] = newData["id"];
                    data["password"] = newData["user"]["password"];
                    await updateStateOfficer(data)
                      .then((res) => {
                        generateNotificationFunctional(
                          res,
                          enqueueSnackbar,
                          "State Officers details has been updated successfully!"
                        );
                        resolve();
                      })
                      .catch((error) => {
                        reject(error);
                      });
                  }),
              }}
              actions={[
                {
                  icon: "refresh",
                  tooltip: "Refresh Data",
                  isFreeAction: true,
                  onClick: () =>
                    tableRef.current && tableRef.current.onQueryChange(),
                },
              ]}
              options={{
                search: true,
                exportButton: true,
                grouping: true,
                pageSize: 100,
                pageSizeOptions: [
                  10,
                  20,
                  30,
                  50,
                  75,
                  100,
                  500,
                  1000,
                  2000,
                  5000,
                ],
                headerStyle: {
                  zIndex: 8,
                },
                maxBodyHeight: 500,
                // filtering: true
              }}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
