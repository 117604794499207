import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// creates a beautiful scrollbar
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
// core components
// import Footer from "components/Footer/Footer.js";
// import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import LoginPage from "views/Auth/Login";
import LogoutPage from "views/Auth/Logout";

import { isLoggedIn } from "utils/utilities";

const switchRoutes = (
  <Switch>
    <Route
      path="/auth/login"
      render={(props) => {
        if (isLoggedIn()) {
          return (
            <Redirect
              to={{
                pathname: "/admin/dashboard",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        } else {
          return <LoginPage {...props} />;
        }
      }}
    />
    <Route
      path="/auth/logout"
      render={(props) => {
        if (!isLoggedIn()) {
          return (
            <Redirect
              to={{
                pathname: "/auth/login",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        } else {
          return <LogoutPage {...props} />;
        }
      }}
    />
    <Redirect from="/auth" to="/auth/login" />
  </Switch>
);

export default function Auth({ ...rest }) {
  return (
    <React.Fragment>
      <React.Fragment>
        <CssBaseline />
        <Container style={{ minHeight: "88vh" }}>{switchRoutes}</Container>
        {/* <Footer /> */}
      </React.Fragment>
    </React.Fragment>
  );
}
