import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import AsyncSelect from "react-select/async";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Grid, Box, Typography, AppBar, Tabs, Tab } from "@material-ui/core";

import PropTypes from "prop-types";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import TransferWithinAStationIcon from "@material-ui/icons/TransferWithinAStation";
// import HistoryIcon from "@material-ui/icons/History";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import MaterialTable from "material-table";
import { useSnackbar } from "notistack";

import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";

import {
  transferPatientToDistrict,
  updateScreeningPrediction,
  updateScreeningStatusToOperated,
} from "utils/apis";
import { generateNotificationFunctional, get } from "utils/utilities";
import { API_SERVER } from "config";

const backdropStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TableList() {
  const accessLevel = sessionStorage.getItem("accessLevel");
  const classes = useStyles();
  const backdropClasses = backdropStyles();

  const { enqueueSnackbar } = useSnackbar();
  const tableRef = React.createRef();
  const [loading, setLoading] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedScreening, setSelectedScreening] = React.useState({});
  const [districts, setDistricts] = React.useState({});
  const [
    selectedTransferDistrict,
    setSelectedTransferDistrict,
  ] = React.useState({});
  const [openValidateImageDialog, setOpenValidateImageDialog] = React.useState(
    false
  );

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const ref = React.createRef();

  const loadDistricts = (inputValue) =>
    new Promise((resolve, reject) => {
      let url = API_SERVER + "/dashboard/districts/?";
      url += "search=" + inputValue;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
          },
        })
        .then((result) => {
          setDistricts(result.data.results);
          let filteredDistricts = [];
          districts.forEach((district) => {
            filteredDistricts.push({
              value: district.id,
              label: String(district.name) + "[" + String(district.code) + "]",
            });
          });
          resolve(filteredDistricts);
        })
        .catch((error) => {
          reject(error);
        });
    });

  const handleDistrictSelectOnchange = (district, key) => {
    switch (key.action) {
      case "select-option":
        setSelectedTransferDistrict(district);
        break;
      default:
        console.log("default case");
        break;
    }
  };

  async function transferPatient() {
    setLoading(true);
    setOpenModal(false);
    let data = {};
    data["id"] = selectedScreening["id"];
    data["target_district"] = selectedTransferDistrict["value"];
    let res = await transferPatientToDistrict(data);
    generateNotificationFunctional(
      res,
      enqueueSnackbar,
      "Patient transferred successfully!"
    );
    if (get(["status"])(res) === null || get(["status"])(res) !== 200) {
      setOpenModal(true);
    } else {
      tableRef.current && tableRef.current.onQueryChange();
    }
    setLoading(false);
  }

  async function updateEyePrediction(eye, verificationStatus) {
    const data = {
      ...selectedScreening,
      eye: eye,
      verification_status: verificationStatus,
    };
    let res = await updateScreeningPrediction(data);
    generateNotificationFunctional(
      res,
      enqueueSnackbar,
      "Prediction Verifaction feedbacak updated!"
    );
  }

  return (
    <React.Fragment>
      <Backdrop className={backdropClasses.backdrop} open={loading} ref={ref}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Transfer Patient: {selectedScreening["patient_id"]}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Choose a new district</DialogContentText>
          <AsyncSelect
            cacheOptions
            defaultOptions
            loadOptions={loadDistricts}
            onChange={handleDistrictSelectOnchange}
          />
          <DialogActions style={{ paddingTop: "10rem" }}>
            <Button
              onClick={() => {
                setOpenModal(false);
              }}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={() => {
                transferPatient();
              }}
            >
              Transfer
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openValidateImageDialog}
        onClose={() => {
          setOpenValidateImageDialog(false);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Validate Screening Predictions of Patient:
          <br />
          {selectedScreening["patient_id"]}
        </DialogTitle>
        <DialogContent>
          <AppBar position="static">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="Validate eye images prediction"
              variant="fullWidth"
            >
              <Tab label="Left Eye" {...a11yProps(0)} />
              <Tab label="Right Eye" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            AI Model Prediction: {selectedScreening["is_left_eye_ai_result"]}
            <br />
            {selectedScreening["is_left_eye_cataract_positive"]
              ? "Cataract Present"
              : "No Cataract Present"}
            <br />
            {selectedScreening["left_eye_image_url"] !== "" &&
            selectedScreening["left_eye_image_url"] !== null ? (
              <img
                src={selectedScreening["left_eye_image_url"]}
                alt="Left Eye"
              />
            ) : (
              "No left Eye Image"
            )}
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="center"
            >
              <IconButton
                color="primary"
                aria-label="Confirm the prediction"
                disabled={selectedScreening["left_eye_image_url"] === null}
                onClick={() => {
                  updateEyePrediction("LEFT", true);
                }}
              >
                <CheckIcon />
              </IconButton>
              <IconButton
                color="primary"
                aria-label="Report wrong prediction"
                disabled={selectedScreening["left_eye_image_url"] === null}
                onClick={() => {
                  updateEyePrediction("LEFT", false);
                }}
              >
                <ClearIcon />
              </IconButton>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1}>
            AI Model Prediction: {selectedScreening["is_right_eye_ai_result"]}
            <br />
            {selectedScreening["is_right_eye_cataract_positive"]
              ? "Cataract Present"
              : "No Cataract Present"}
            <br />
            {selectedScreening["right_eye_image_url"] !== "" &&
            selectedScreening["right_eye_image_url"] !== null ? (
              <img
                src={selectedScreening["right_eye_image_url"]}
                alt="Right Eye"
              />
            ) : (
              "No Right Eye Image"
            )}
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="center"
            >
              <IconButton
                color="primary"
                aria-label="Confirm the prediction"
                disabled={selectedScreening["right_eye_image_url"] === null}
                onClick={() => {
                  updateEyePrediction("RIGHT", true);
                }}
              >
                <CheckIcon />
              </IconButton>
              <IconButton
                color="primary"
                aria-label="Report wrong prediction"
                disabled={selectedScreening["right_eye_image_url"] === null}
                onClick={() => {
                  updateEyePrediction("RIGHT", false);
                }}
              >
                <ClearIcon />
              </IconButton>
            </Grid>
          </TabPanel>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenValidateImageDialog(false);
            }}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Patient Details</h4>
              <p className={classes.cardCategoryWhite}>
                List of all the the screenings done
              </p>
            </CardHeader>
            <CardBody>
              <MaterialTable
                title="Patient Screening Details"
                tableRef={tableRef}
                columns={[
                  {
                    title: "Patient ID",
                    field: "patient_id",
                    editable: "never",
                  },
                  {
                    title: "Status",
                    field: "status",
                    lookup: {
                      OPERATED: "Operated",
                      PENDING: "Pending",
                      DELAYED: "Delayed",
                      REFERRED: "Referred",
                    },
                  },
                  {
                    title: "Validate",
                    editable: "never",
                    render: (rowData) => {
                      return (
                        <IconButton
                          color="primary"
                          aria-label="Validate Predictions by AI Model"
                          onClick={async () => {
                            await setSelectedScreening(rowData);
                            setOpenValidateImageDialog(true);
                          }}
                        >
                          <VerifiedUserIcon />
                        </IconButton>
                      );
                    },
                  },
                  { title: "Name", field: "name", editable: "never" },
                  {
                    title: "Contact No",
                    field: "contact_no",
                    editable: "never",
                  },
                  {
                    title: "Screening Date",
                    field: "screening_date",
                    type: "date",
                    editable: "never",
                  },
                  {
                    title: "Is Left Eye has Cataract",
                    field: "is_left_eye_cataract_positive",
                    type: "boolean",
                    editable: "never",
                  },
                  {
                    title: "Is Right Eye has Cataract",
                    field: "is_right_eye_cataract_positive",
                    type: "boolean",
                    editable: "never",
                  },
                  {
                    title: "Doctor's verdict on left eye",
                    field: "left_eye_verdict_by_doctor",
                    type: "boolean",
                    editable: "never",
                  },
                  {
                    title: "Doctor's verdict on right eye",
                    field: "right_eye_verdict_by_doctor",
                    type: "boolean",
                    editable: "never",
                  },
                  {
                    title: "District",
                    field: "district.name",
                    filtering: false,
                    render: (rowData) => {
                      return rowData.district.name;
                    },
                    editable: "never",
                  },
                  {
                    title: "Village",
                    field: "village",
                    editable: "never",
                  },
                  {
                    title: "HSC",
                    field: "HSC",
                    editable: "never",
                  },
                  {
                    title: "HUD",
                    field: "HUD",
                    editable: "never",
                  },
                  {
                    title: "Address",
                    field: "address",
                    editable: "never",
                  },
                ]}
                data={(query) =>
                  new Promise((resolve, reject) => {
                    let url = API_SERVER + "/dashboard/screenings/?";
                    url += "per_page=" + query.pageSize;
                    url += "&page=" + (query.page + 1);
                    url += "&cataract=false";
                    if (query.search !== null) {
                      url += "&search=" + query.search;
                    }
                    if (accessLevel === "DISTRICTOFFICER") {
                      let districts = [];
                      let districtOfficerProfile = JSON.parse(
                        sessionStorage.getItem("districtOfficerProfile")
                      );
                      districts = [districtOfficerProfile["district"]["id"]];
                      if (districts.length > 0) {
                        let districtsParams = "[";
                        for (let i in districts) {
                          if (Number.isInteger(districts[i])) {
                            districtsParams += districts[i];
                            if (
                              parseInt(i) !== parseInt(districts.length - 1)
                            ) {
                              districtsParams += ",";
                            }
                          }
                        }
                        districtsParams += "]";
                        url += "&districts=" + districtsParams;
                      }
                    }
                    axios
                      .get(url, {
                        headers: {
                          Authorization:
                            "Bearer " + sessionStorage.getItem("accessToken"),
                        },
                      })
                      .then((result) => {
                        resolve({
                          data: result.data.results,
                          page: query.page,
                          totalCount: result.data.count,
                        });
                      });
                  })
                }
                actions={[
                  (rowData) => ({
                    icon: TransferWithinAStationIcon,
                    tooltip: "Transfer Patient",
                    disabled:
                      !rowData.is_left_eye_cataract_positive &&
                      !rowData.is_right_eye_cataract_positive,
                    onClick: (event, rowData) => {
                      setSelectedScreening(rowData);
                      setOpenModal(true);
                    },
                    toolbarButtonAlignment: "left",
                  }),
                  {
                    icon: "refresh",
                    tooltip: "Refresh Data",
                    isFreeAction: true,
                    onClick: () =>
                      tableRef.current && tableRef.current.onQueryChange(),
                  },
                ]}
                options={{
                  search: true,
                  exportButton: true,
                  grouping: true,
                  pageSize: 100,
                  pageSizeOptions: [
                    10,
                    20,
                    30,
                    50,
                    75,
                    100,
                    500,
                    1000,
                    2000,
                    5000,
                  ],
                  headerStyle: {
                    zIndex: 8,
                  },
                  maxBodyHeight: 500,
                  // filtering: true
                }}
                editable={{
                  onRowUpdate: (newData, oldData) =>
                    new Promise(async (resolve, reject) => {
                      if (newData["status"] !== "OPERATED") {
                        enqueueSnackbar(
                          "Sorry! You can manually change the status to OPERATED only.",
                          { variant: "error" }
                        );
                        reject();
                      } else {
                        const data = {
                          id: newData["id"],
                          status: newData["status"],
                        };
                        let res = await updateScreeningStatusToOperated(data);
                        generateNotificationFunctional(
                          res,
                          enqueueSnackbar,
                          "Screening Status chaned to OPERATED successfully!"
                        );
                      }
                      resolve();
                    }),
                }}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </React.Fragment>
  );
}
