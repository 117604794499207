/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
// import SettingsIcon from "@material-ui/icons/Settings";
// import StarHalfIcon from "@material-ui/icons/StarHalf";
// import PeopleIcon from "@material-ui/icons/People";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import UserProfile from "views/UserProfile/UserProfile.js";
import ScreeningList from "views/Screenings/ScreeningList.js";
import ScreeningLogs from "views/Screenings/ScreeningLogs.js";
import FieldWorkersList from "views/FieldWorkers/FieldWorkersList.js";
import DistrictsList from "views/Districts/DistrictsList.js";
import DistrictOfficersList from "views/Districts/DistrictOfficersList.js";
import StateOfficersList from "views/States/StateOfficersList.js";
// import Typography from "views/Typography/Typography.js";
// import Icons from "views/Icons/Icons.js";
// import NotificationsPage from "views/Notifications/Notifications.js";
// import LoginPage from "views/Auth/Login";
// import LogoutPage from "views/Auth/Logout";
// core components/views for RTL layout

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
    showInNavbar: true,
    accessLevel: "ALL",
  },
  {
    path: "/user",
    name: "User Profile",
    icon: Person,
    component: UserProfile,
    layout: "/admin",
    showInNavbar: true,
    accessLevel: "ALL",
  },
  {
    path: "/stateOfficers",
    name: "State Officers",
    icon: "content_paste",
    component: StateOfficersList,
    layout: "/admin",
    showInNavbar: true,
    accessLevel: "STATEOFFICER",
  },
  {
    path: "/districts",
    name: "District Details",
    icon: "content_paste",
    component: DistrictsList,
    layout: "/admin",
    showInNavbar: true,
    accessLevel: "STATEOFFICER",
  },
  {
    path: "/districtsOfficers",
    name: "District Officers",
    icon: "content_paste",
    component: DistrictOfficersList,
    layout: "/admin",
    showInNavbar: true,
    accessLevel: "STATEOFFICER",
  },
  {
    path: "/fieldWorkers",
    name: "Field Workers",
    icon: "content_paste",
    component: FieldWorkersList,
    layout: "/admin",
    showInNavbar: true,
    accessLevel: "ALL",
  },
  {
    path: "/screenings",
    name: "Patient Details",
    icon: LibraryBooks,
    component: ScreeningList,
    layout: "/admin",
    showInNavbar: true,
    accessLevel: "ALL",
  },
  {
    path: "/logs",
    name: "Screening Logs",
    icon: LibraryBooks,
    component: ScreeningLogs,
    layout: "/admin",
    showInNavbar: true,
    accessLevel: "ALL",
  },
  // {
  //   path: "/typography",
  //   name: "Reports",
  //   icon: LibraryBooks,
  //   component: Typography,
  //   layout: "/admin",
  //   showInNavbar: true,
  //   accessLevel: 'all'
  // },
  // {
  //   path: "/icons",
  //   name: "Password Change",
  //   icon: SettingsIcon,
  //   component: Icons,
  //   layout: "/admin",
  //   showInNavbar: true,
  //   accessLevel: 'all'
  // },
  // {
  //   path: "/notifications",
  //   name: "Ratings",
  //   icon: StarHalfIcon,
  //   component: NotificationsPage,
  //   layout: "/admin",
  //   showInNavbar: true,
  //   accessLevel: 'all'
  // },
  // {
  //   path: "/login",
  //   name: "Login Page",
  //   icon: PeopleIcon,
  //   component: LoginPage,
  //   layout: "/auth",
  //   showInNavbar: false,
  //   accessLevel: "ALL",
  // },
  // {
  //   path: "/logout",
  //   name: "Logout Page",
  //   icon: PeopleIcon,
  //   component: LogoutPage,
  //   layout: "/auth",
  //   showInNavbar: false,
  //   accessLevel: "ALL",
  // },
];

export default dashboardRoutes;
