/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
// import { createBrowserHistory } from "history";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";

// core components
import Admin from "layouts/Admin.js";
import Auth from "layouts/Auth";
import { SnackbarProvider } from "notistack";
import "assets/css/material-dashboard-react.css?v=1.9.0";
// import { useHistory } from "react-router-dom";

// const hist = createBrowserHistory();

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#0f6b00",
      contrastText: "#fff",
    },
  },
});

function App() {
  // let history = useHistory();

  // React.useEffect(() => {
  //   if (isLoggedIn()) {
  //     history.push("/admin/dashboard");
  //   } else {
  //     history.push("/auth/login");
  //   }
  // });
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        preventDuplicate
      >
        <HashRouter
        // history={hist}
        >
          <Switch>
            <Route path="/admin" component={Admin} />
            <Route path="/auth" component={Auth} />
            {/* <Route path="/admin/dashboard" render={(props) =>{

            }} /> */}
            {/* {isLoggedIn() ? (
                <Redirect push from="/" to="/admin/dashboard" />
              ) : (
                <Redirect push from="/" to="/auth/login" />
              )} */}
            {/* </Route> */}
            <Redirect from="/" to="/admin/dashboard" />
          </Switch>
        </HashRouter>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
