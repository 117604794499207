import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Grid,
  Backdrop,
  CircularProgress,
  Button,
  Chip,
  Avatar,
} from "@material-ui/core";

import { useSnackbar } from "notistack";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import {
  popNotificationFunctional,
  get,
  getFullFormAbbrevation,
  generateNotificationFunctional,
} from "utils/utilities";
import { getProfile, updateProfile } from "utils/apis";

import avatar from "assets/img/tnega.png";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

const backdropStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function UserProfile() {
  const backdropClasses = backdropStyles();
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const userLocal = JSON.parse(sessionStorage.getItem("user"));

  const [firstName, setFirstName] = React.useState(
    get(["first_name"])(userLocal)
  );
  const [lastName, setLastName] = React.useState(get(["last_name"])(userLocal));
  const [contactNo, setContactNo] = React.useState(
    get(["contact_no"])(userLocal)
  );
  const [initialDataLoaded, setInitialDataLoaded] = React.useState(false);
  const [email, setEmail] = React.useState(get(["email"])(userLocal));
  const [officeName, setOfficeName] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [accessLevel, setAccessLevel] = React.useState("");
  const [officerType, setOfficerType] = React.useState("");

  const ref = React.createRef();

  React.useEffect(() => {
    async function loadInitialData() {
      if (initialDataLoaded) {
        return;
      }
      setLoading(true);
      let res = await getProfile();
      let user = get(["data", "user"])(res);
      await sessionStorage.setItem("user", JSON.stringify(user));
      let accessLevel = get(["data", "user", "access_level"])(res);
      await setFirstName(get(["data", "user", "first_name"])(res));
      await setLastName(get(["data", "user", "last_name"])(res));
      await setContactNo(get(["data", "user", "contact_no"])(res));
      await setEmail(get(["data", "user", "email"])(res));
      setAccessLevel(accessLevel);
      await sessionStorage.setItem("accessLevel", accessLevel);
      if (accessLevel !== null && accessLevel !== "FIELDWORKER") {
        if (accessLevel === "STATEOFFICER") {
          let officerType = get([
            "data",
            "user",
            "state_officer_profile",
            "type",
          ])(res);
          setOfficerType(officerType);
          let stateOfficerProfileStatus = get([
            "data",
            "user",
            "state_officer_profile",
            "active",
          ])(res);
          if (stateOfficerProfileStatus) {
            let stateOfficerProfile = get([
              "data",
              "user",
              "state_officer_profile",
            ])(res);
            await sessionStorage.setItem(
              "stateOfficerProfile",
              JSON.stringify(stateOfficerProfile)
            );
          } else {
            popNotificationFunctional(
              enqueueSnackbar,
              "Sorry! an error occured we could not get your profile",
              "error"
            );
          }
        } else if (accessLevel === "DISTRICTOFFICER") {
          let officerType = get([
            "data",
            "user",
            "district_officer_profile",
            "type",
          ])(res);
          setOfficerType(officerType);
          let districtOfficerProfileStatus = get([
            "data",
            "user",
            "district_officer_profile",
            "active",
          ])(res);
          if (districtOfficerProfileStatus) {
            let districtOfficerProfile = get([
              "data",
              "user",
              "district_officer_profile",
            ])(res);
            await sessionStorage.setItem(
              "districtOfficerProfile",
              JSON.stringify(districtOfficerProfile)
            );
          } else {
            popNotificationFunctional(
              enqueueSnackbar,
              "Sorry! an error occured we could not get your profile",
              "error"
            );
          }
        }
      }
      if (accessLevel === "STATEOFFICER") {
        await setOfficeName(
          get(["data", "user", "state_officer_profile", "office_name"])(res)
        );
        await setAddress(
          get(["data", "user", "state_officer_profile", "address"])(res)
        );
      } else if (accessLevel === "DISTRICTOFFICER") {
        await setOfficeName(
          get(["data", "user", "district_officer_profile", "office_name"])(res)
        );
        await setAddress(
          get(["data", "user", "district_officer_profile", "address"])(res)
        );
      }
      await setInitialDataLoaded(true);
      setLoading(false);
    }
    loadInitialData();
    return () => {
      setInitialDataLoaded(true);
      setLoading(false);
    };
  });

  const updateProfileButtonHandle = async () => {
    setLoading(true);
    let data = {
      first_name: firstName,
      last_name: lastName,
      contact_no: contactNo,
      email: email,
      office_name: officeName,
      address: address,
    };
    let res = await updateProfile(data);
    await setInitialDataLoaded(false);
    setLoading(false);
    generateNotificationFunctional(
      res,
      enqueueSnackbar,
      "Profile updated successfully!"
    );
  };

  return (
    <div>
      <Backdrop className={backdropClasses.backdrop} open={loading} ref={ref}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Edit Profile</h4>
              <p className={classes.cardCategoryWhite}>Update your profile</p>
            </CardHeader>
            <CardBody>
              <Grid container spacing={2}>
                <Grid item container spacing={2}>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      variant="outlined"
                      label="First Name"
                      id="first-name"
                      value={firstName}
                      fullWidth
                      onChange={(event) => {
                        setFirstName(event.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      variant="outlined"
                      label="Last Name"
                      id="last-name"
                      value={lastName}
                      fullWidth
                      onChange={(event) => {
                        setLastName(event.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      variant="outlined"
                      label="Contact No"
                      id="contact_no"
                      value={contactNo}
                      fullWidth
                      onChange={(event) => {
                        setContactNo(event.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      variant="outlined"
                      label="Email address"
                      id="email-address"
                      value={email}
                      fullWidth
                      onChange={(event) => {
                        setEmail(event.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      variant="outlined"
                      label="Office"
                      id="office"
                      value={officeName}
                      fullWidth
                      onChange={(event) => {
                        setOfficeName(event.target.value);
                      }}
                      multiline
                      rows={1}
                    />
                  </Grid>
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      variant="outlined"
                      label="Address"
                      id="address"
                      value={address}
                      fullWidth
                      onChange={(event) => {
                        setAddress(event.target.value);
                      }}
                      multiline
                      rows={5}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </CardBody>
            <CardFooter>
              <Button
                color="primary"
                onClick={() => {
                  updateProfileButtonHandle();
                }}
              >
                Update Profile
              </Button>
            </CardFooter>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Card profile>
            <CardAvatar profile>
              <a href="#pablo" onClick={(e) => e.preventDefault()}>
                <img src={avatar} alt="..." />
              </a>
            </CardAvatar>
            <CardBody profile>
              <h3 className={classes.cardTitle} style={{ margin: 5 }}>
                {firstName + " " + lastName}
              </h3>
              <h6 className={classes.cardCategory} style={{ margin: 0 }}>
                <Chip
                  avatar={
                    <Avatar>
                      {accessLevel === "STATEOFFICER" ? "S" : "D"}
                    </Avatar>
                  }
                  label={getFullFormAbbrevation(officerType, accessLevel)}
                />
              </h6>
              <h6 style={{ margin: 5, textTransform: "none" }}>{email}</h6>
              <h6 className={classes.cardTitle} style={{ margin: 5 }}>
                {contactNo}
              </h6>
              <h6 style={{ margin: 5 }}>
                <b>{officeName} </b>
              </h6>
              <p style={{ margin: 5 }} className={classes.description}>
                {address}
              </p>
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
