import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import MaterialTable from "material-table";
import { useSnackbar } from "notistack";
// import { get } from "utils/utilities";
import { generateNotificationFunctional } from "utils/utilities";
import { addDistrict, updateDistrict } from "utils/apis";

import { API_SERVER } from "config";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);
const stateOfficerProfile = JSON.parse(
  sessionStorage.getItem("stateOfficerProfile")
);

export default function TableList() {
  const classes = useStyles();
  const tableRef = React.createRef();
  const { enqueueSnackbar } = useSnackbar();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>District Details</h4>
            <p className={classes.cardCategoryWhite}>
              List of all the the districts
            </p>
          </CardHeader>
          <CardBody>
            <MaterialTable
              title="District Details"
              tableRef={tableRef}
              columns={[
                { title: "ID", field: "id", editable: "never" },
                {
                  title: "Name",
                  field: "name",
                },
                {
                  title: "Short Code",
                  field: "code",
                },
                {
                  title: "Maximum Field Workers Limit",
                  field: "max_field_workers_limit",
                  type: "numeric",
                  initialEditValue: 50,
                },
                {
                  title: "State",
                  field: "district.name",
                  filtering: false,
                  editable: "never",
                  render: (rowData) => {
                    return rowData.state.name;
                  },
                },
              ]}
              data={(query) =>
                new Promise((resolve, reject) => {
                  let url = API_SERVER + "/dashboard/districts/?";
                  url += "per_page=" + query.pageSize;
                  url += "&page=" + (query.page + 1);
                  if (query.search !== null) {
                    url += "&search=" + query.search;
                  }
                  axios
                    .get(url, {
                      headers: {
                        Authorization:
                          "Bearer " + sessionStorage.getItem("accessToken"),
                      },
                    })
                    // .get(url, {})
                    // .then(response => response.json())
                    .then((result) => {
                      resolve({
                        data: result.data.results,
                        page: query.page,
                        totalCount: result.data.count,
                      });
                    });
                })
              }
              editable={{
                onRowAdd: (newData) =>
                  new Promise(async (resolve, reject) => {
                    newData["state"] = stateOfficerProfile.state.id;
                    await addDistrict(newData)
                      .then((res) => {
                        generateNotificationFunctional(
                          res,
                          enqueueSnackbar,
                          "New District was added successfully!"
                        );
                        resolve();
                      })
                      .catch((error) => {
                        reject(error);
                      });
                  }),
                onRowUpdate: (newData, oldData) =>
                  new Promise(async (resolve, reject) => {
                    await updateDistrict(newData)
                      .then((res) => {
                        generateNotificationFunctional(
                          res,
                          enqueueSnackbar,
                          "District has been updated successfully!"
                        );
                        resolve();
                      })
                      .catch((error) => {
                        reject(error);
                      });
                  }),
              }}
              actions={[
                // (rowData) => ({
                //   icon: TransferWithinAStationIcon,
                //   tooltip: "Transfer Patient",
                //   // onClick: (event, rowData) =>
                //   //   window.confirm("You want to delete " + rowData.name),
                //   disabled:
                //     !rowData.is_left_eye_cataract_positive &&
                //     !rowData.is_right_eye_cataract_positive,
                // }),
                // (rowData) => ({
                //   icon: HistoryIcon,
                //   tooltip: "Get Transfer History of the Patient",
                //   // onClick: (event, rowData) =>
                //   //   window.confirm("You want to delete " + rowData.name),
                //   disabled:
                //     !rowData.is_left_eye_cataract_positive &&
                //     !rowData.is_right_eye_cataract_positive,
                // }),
                {
                  icon: "refresh",
                  tooltip: "Refresh Data",
                  isFreeAction: true,
                  onClick: () =>
                    tableRef.current && tableRef.current.onQueryChange(),
                },
              ]}
              options={{
                search: true,
                exportButton: true,
                grouping: true,
                pageSize: 100,
                pageSizeOptions: [
                  10,
                  20,
                  30,
                  50,
                  75,
                  100,
                  500,
                  1000,
                  2000,
                  5000,
                ],
                headerStyle: {
                  zIndex: 8,
                },
                maxBodyHeight: 500,
                // filtering: true
              }}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
