import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useHistory } from "react-router-dom";

import { login } from "utils/apis";
import { useSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import { get } from "utils/utilities";
import {
  generateNotificationFunctional,
  popNotificationFunctional,
} from "utils/utilities";

import TNeGALogo from "assets/img/tnega.png";
import NHMLogo from "assets/img/nhm.png";
import TNLogo from "assets/img/tn.png";
import { encryptWithPublicKey } from "utils/utilities";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://tnega.tn.gov.in/">
        Developed by TNeGA
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn() {
  let history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [userIdentifier, setUserIdentifier] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  // const [errorMessage, setErrorMessage] = useState("");
  const errorMessageForUnprivilegedAccess =
    "Sorry! You do not have required privileges to access the dashboard. Kindly contact TNeGA or NHM for the credentials.";

  async function setSessionTokens(res) {
    await sessionStorage.setItem("accessToken", res.data.access_token);
    await sessionStorage.setItem("refreshToken", res.data.refresh_token);
    popNotificationFunctional(
      enqueueSnackbar,
      "Redirecting you to the Dashboard",
      "success"
    );
    // history.push("/admin/dashboard");
    window.location.reload();
  }

  async function handleLoginFormSubmit(event) {
    event.preventDefault();
    await setLoading(true);
    let data = {};
    data["user_identifier"] = userIdentifier;
    data["password"] = encryptWithPublicKey(password);
    // setErrorMessage("");
    try {
      let res = await login(data);
      generateNotificationFunctional(res, enqueueSnackbar, "User Identified");
      let status = get(["status"])(res);
      if (status !== null && status === 200) {
        let user = get(["data", "user"])(res);
        await sessionStorage.setItem("user", JSON.stringify(user));
        let accessLevel = get(["data", "user", "access_level"])(res);
        await sessionStorage.setItem("accessLevel", accessLevel);
        if (accessLevel !== null && accessLevel !== "FIELDWORKER") {
          if (accessLevel === "STATEOFFICER") {
            let stateOfficerProfileStatus = get([
              "data",
              "user",
              "state_officer_profile",
              "active",
            ])(res);
            if (stateOfficerProfileStatus) {
              let stateOfficerProfile = get([
                "data",
                "user",
                "state_officer_profile",
              ])(res);
              await sessionStorage.setItem(
                "stateOfficerProfile",
                JSON.stringify(stateOfficerProfile)
              );
              setSessionTokens(res);
            } else {
              popNotificationFunctional(
                enqueueSnackbar,
                errorMessageForUnprivilegedAccess,
                "error"
              );
            }
          } else if (accessLevel === "DISTRICTOFFICER") {
            let districtOfficerProfileStatus = get([
              "data",
              "user",
              "district_officer_profile",
              "active",
            ])(res);
            if (districtOfficerProfileStatus) {
              let districtOfficerProfile = get([
                "data",
                "user",
                "district_officer_profile",
              ])(res);
              await sessionStorage.setItem(
                "districtOfficerProfile",
                JSON.stringify(districtOfficerProfile)
              );
              setSessionTokens(res);
            } else {
              popNotificationFunctional(
                enqueueSnackbar,
                errorMessageForUnprivilegedAccess,
                "error"
              );
            }
          }
        } else {
          popNotificationFunctional(
            enqueueSnackbar,
            errorMessageForUnprivilegedAccess,
            "error"
          );
        }
      }
    } catch (error) {
      // setErrorMessage(error);
      popNotificationFunctional(
        enqueueSnackbar,
        "Error connecting to the server. Please check your internet connection.",
        "error"
      );
    }
    setLoading(false);
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid
            item
            container
            direction="row"
            justify="center"
            alignItems="center"
            sm={4}
          >
            <img className={classes.avatar} alt="TNeGA" src={TNeGALogo} />
          </Grid>
          <Grid
            item
            container
            direction="row"
            justify="center"
            alignItems="center"
            sm={4}
          >
            <img className={classes.avatar} alt="TN Govt" src={TNLogo} />
          </Grid>
          <Grid
            item
            container
            direction="row"
            justify="center"
            alignItems="center"
            sm={4}
          >
            <img className={classes.avatar} alt="NHM" src={NHMLogo} />
          </Grid>
        </Grid>
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        <form
          className={classes.form}
          noValidate
          // onSubmit={handleLoginFormSubmit}
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="User Identifier"
            name="email"
            autoComplete="email"
            value={userIdentifier}
            onChange={(event) => {
              setUserIdentifier(event.target.value);
            }}
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            value={password}
            onChange={(event) => {
              setPassword(event.target.value);
            }}
            autoComplete="current-password"
          />

          {loading ? (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={true}
            >
              <CircularProgress />
            </Button>
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleLoginFormSubmit}
            >
              Login
            </Button>
          )}
          <Grid container>
            <Grid item>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
